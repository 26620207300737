<template>
  <el-menu
    :default-active="activeMenu"
    class="el-menu-demo"
    mode="horizontal"
    background-color="#fff"
    text-color="#333333"
    active-text-color="#333333"
    router
  >
    <el-menu-item index="/home">首页</el-menu-item>
    <el-menu-item index="/AboutUs">关于我们</el-menu-item>
    <el-menu-item index="/publishInfo">信息发布</el-menu-item>
    <el-menu-item index="/Mypublish">我的发布</el-menu-item>
  </el-menu>
  <!-- <div class="menu">
    <router-link
      v-for="(item, index) in routerList"
      :key="index"
      :class="{ 'menu-litm': true, 'active-menu-item': index == onactive }"
      :to="item.to"
      @click="routerClick(index)"
      >{{ item.title }}</router-link
    >
  </div> -->
</template>
<script setup>
  import { computed, ref, defineProps } from "vue"
  import { useRoute, useRouter } from "vue-router"
  const routerList = [
    {
      to: "/home",
      title: "首页",
    },
    {
      to: "/AboutUs",
      title: "AboutUs",
    },
    {
      to: "/publishInfo",
      title: "信息发布",
    },
    {
      to: "/Mypublish",
      title: "我的发布",
    },
  ]
  const props = defineProps({
    active: {
      type: Number,
    },
  })
  console.log(props.active)
  const onactive = ref(0)
  console.log(onactive.value)
  // 路由选中状态
  const routerClick = (index) => {
    onactive.value = index
    console.log(onactive.value)
  }

  const route = useRoute()
  const router = useRouter()
  const activeMenu = computed(() => {
    const { path } = route
    return path
  })
  activeMenu.value == "/AboutUs"
    ? (onactive.value = 1)
    : activeMenu.value == "/home"
    ? (onactive.value = 0)
    : activeMenu.value == "/publishInfo"
    ? (onactive.value = 2)
    : activeMenu.value == "/Mypublish"
    ? (onactive.value = 3)
    : (onactive.value = 0)
  console.log(activeMenu.value)
  const liClick = () => {
    if (activeMenu.value !== "/home") {
      router.push("/home")
      onactive.value = 0
    }
  }
</script>
<style lang="scss" scoped>
  .el-menu--horizontal > .el-menu-item {
    border-bottom: none;
    text-decoration: none;
  }
  .el-menu-item.is-active {
    color: #fff;
    span {
      color: #fff !important;
    }
  }
  .el-menu--horizontal {
    padding: 0 360px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
  }
  .menu {
    width: 100%;
    height: 40px;
    // background-color: #2d2d2d;
    padding: 0 360px;
    display: flex;
    box-sizing: border-box;
  }
  .menu-ul {
    flex: 5;
    display: flex;
  }
  .menu-ul li {
    flex: 1;
    line-height: 70px;
    color: #fff;
    text-align: center;
  }
  .menu-ul li a {
    display: block;
    line-height: 70px;
    color: #fff;
    text-align: center;
  }
  .menu-litm {
    display: block;
    flex: 1;
    line-height: 70px;
    color: #fff;
    text-align: center;
  }
  .active-menu-item {
    background-color: #ff8420;
  }
</style>
